const BASE_URL = process.env.REACT_APP_BASE_URL;

export const paymentApi = {
  getEwayPublicAPIKey: async () => {
    try {
      const response = await fetch(`${BASE_URL}/GetEwayPublicKey`);
      if (!response.ok) throw new Error("Failed to get client token");
      console.log("dat", response);
      const data = await response.text();
      return data;
    } catch (error) {
      console.error("Error fetching PayPal client ID:", error);
      throw error;
    }
  },
//eway api
  processPayment: async (paymentData) => {
    try {
      const response = await fetch(`${BASE_URL}/EwayApi`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(paymentData),
      });
  
      const data = await response.json();
      return data; 
    } catch (error) {
      console.error("Error processing payment:", error);
      return {
        message: "Network or server error occurred",
        error: error.message
      };
    }
  },

  createPayPalOrder: async (amount, currency) => {
    try {
      const response = await fetch(`${BASE_URL}/CreatePayPalOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ amount, currency }),
      });
      const data = await response.json();
      return data.id; // Return order ID
    } catch (error) {
      console.error("Error creating PayPal order:", error);
      throw error;
    }
  },

  capturePayPalOrder: async (orderId) => {
    try {
      const response = await fetch(`${BASE_URL}/CapturePayPalOrder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ orderId }),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error capturing PayPal order:", error);
      throw error;
    }
  },

  getPayPalClientId: async () => {
    try {
      const response = await fetch(`${BASE_URL}/GetPayPalClientId`);
      // const response = await fetch(`${BASE_URL}/getBraintreeClientToken`);
      if (!response.ok) throw new Error("Failed to get client token");
      // console.log("dat", response.text());
      const data = await response.text();

      return data;
    } catch (error) {
      console.error("Error fetching PayPal client ID:", error);
      throw error;
    }
  },
};
