/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { selectID } from "../../features/counter/counterSlice";
import { passwordResetRequest } from "../../authConfig";
const MyDetails = (props) => {
  const { pathname, search, push, contactData } = props;

  const { instance, accounts } = useMsal();
  const authID = useSelector(selectID);

  const handlePasswordReset = () => {
    if (!accounts[0]) {
      console.error("No active account found");
      return;
    }

    instance.loginRedirect(passwordResetRequest).catch((e) => {
      console.error(e);
    });
  };
  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        {contactData && (
          <div className="col-lg-12 noLeftPadding noRightPadding">
            <h3 className="tab-section-heading">Account information</h3>
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                <div className="infoBox">
                  <p className="infoBox-lineOne">
                    <strong>Login email:</strong>
                    <span className="">
                      <a className="clrBlue" onClick={handlePasswordReset}>
                        Change password
                      </a>
                    </span>
                  </p>

                  <p>
                    {contactData.emailaddress1}
                    &nbsp;&nbsp;
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Suspense>
    </>
  );
};

export default MyDetails;
