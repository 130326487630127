import React, { useState } from "react";
import { connect, useSelector } from "react-redux";
import { selectID } from "../../../features/counter/counterSlice";
import InputField from "../../../components/InputField";
import SelectField from "../../../components/SelectField";
import { countries, states } from "./billingData";
import { apiService } from "../../../api/dataverseApi";

const UpdateBillingAddressDialog = ({
  isOpen,
  onClose,
  billingAddress,
  onSave,
}) => {
  const [localAddress, setLocalAddress] = useState(billingAddress);
  const authID = useSelector(selectID);
  const handleChange = (e) => {
    setLocalAddress({
      ...localAddress,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    console.log("localAddress", localAddress);
    try {
      const data = {
        ...localAddress,
        emailaddress1: authID.u["signInNames.emailAddress"],
      };
      const response = await apiService.createContact(data);
      console.log(response, "data");

      if (response.success) {
        console.log("User profile updated successfully");
        onClose();
        onSave(localAddress);
      } else {
        alert("Billing address updated failed");
      }
    } catch (err) {
      console.error("Error in updating billing address:", err.message);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="billing-adress-dialog-backdrop" onClick={onClose}>
      <div className="dialog" onClick={(e) => e.stopPropagation()}>
        <button onClick={onClose} className="close-button">
          X
        </button>
        <h2>Update Billing Address</h2>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <InputField
            label="Street Address"
            id="streetAddress"
            name="address1_line1"
            value={localAddress.address1_line1 || ""}
            placeholder="Enter street address"
            onChange={handleChange}
          />
          <InputField
            label="Suburb"
            id="suburb"
            name="address1_city"
            value={localAddress.address1_city || ""}
            placeholder="Enter suburb"
            onChange={handleChange}
          />
          <SelectField
            label="State"
            id="state"
            name="address1_stateorprovince"
            value={localAddress.address1_stateorprovince || ""}
            options={states}
            onChange={handleChange}
          />
          <SelectField
            label="Country"
            id="country"
            name="address1_country"
            value={localAddress.address1_country || ""}
            options={countries}
            onChange={handleChange}
          />

          <InputField
            label="Postal Code"
            id="postalCode"
            name="address1_postalcode"
            value={localAddress.address1_postalcode || ""}
            placeholder="Enter postal code"
            onChange={handleChange}
          />

          <button type="submit">Save Address</button>
        </form>
      </div>
    </div>
  );
};

export default UpdateBillingAddressDialog;
