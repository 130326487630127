const BASE_URL = process.env.REACT_APP_BASE_URL;

export const createUser = async (email, password, firstName, lastName) => {
  console.log("------create user-----", email, password);
  try {
    const response = await fetch(`${BASE_URL}/B2CManageUser`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, password, firstName, lastName }),
    });

    const data = await response.json();

    if (!response.ok) {
      throw new Error(data.error || "Failed to create user");
    }

    return data;
  } catch (error) {
    console.error("Error creating user:", error);
    throw error;
  }
};

export const updatePassword = async (email, newPassword) => {
  console.log("------update password-----", email, newPassword);
  try {
    const response = await fetch(`${BASE_URL}/B2CManageUser`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email, newPassword }),
    });

    const data = await response.json();

    if (!response.ok) {
      // Handle specific error cases
      throw new Error(
        data.error || data.details || "Failed to update password"
      );
    }

    return data;
  } catch (error) {
    console.error("Error updating password:", error);
    throw error;
  }
};

export const deleteB2CUser = async (email) => {
  console.log("------delete user-----", email);
  try {
    const response = await fetch(`${BASE_URL}/B2CManageUser`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    });

    const data = await response.json();

    if (!response.ok) {
      // Handle specific error cases from the backend
      throw new Error(data.error || data.details || "Failed to delete user");
    }

    return data;
  } catch (error) {
    console.error("Error deleting user:", error);
    throw error;
  }
};
