import React, { useState, useEffect, Suspense } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { apiService } from "../../api/dataverseApi";
import { createUser, deleteB2CUser } from "../../api/B2CApi";

const ManageUsers = (props) => {
  const { subscriptions, mainAccountContact} = props;
  const [showUserForm, setShowUserForm] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
  });
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showPasswordRequirements, setShowPasswordRequirements] =
    useState(false);

  // New state for managing licenses and users
  const [licenseInfo, setLicenseInfo] = useState({
    totalLicenses: 0,
    availableLicenses: 0,
  });

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log("subscriptions", subscriptions, mainAccountContact);
   console.log("subscriptions tg_flags", subscriptions.tg_flags);
    if (!subscriptions || !mainAccountContact?.contactid) {
      console.error("Subscriptions or main account contact ID not available");
      return;
    }
    setLicenseInfo({
      totalLicenses: subscriptions.tg_numberoflicences,
      availableLicenses: subscriptions.tg_numberoflicences,
    });
  }, [subscriptions, mainAccountContact]);

  // Fetch license and user data from backend
  useEffect(() => {
    const fetchChildContacts = async () => {
      if (!mainAccountContact?.contactid) {
        console.error("Main account contact ID not available");
        return;
      }

      setIsLoading(true);
      try {
        const response = await apiService.getChildContacts(
          mainAccountContact?.contactid
        );
        console.log("Child contacts response:", response);

        if (response.success && response.contacts) {
          // Correctly map the response data
          const formattedUsers = response.contacts.map((contact) => ({
            email: contact.email,
            firstName: contact.firstName,
            lastName: contact.lastName,
            contactId: contact.contactId,
            phone: contact.phone,
            status: contact.status,
          }));

          console.log("Formatted users:", formattedUsers);
          setUsers(formattedUsers);

          // Update available licenses based on child count if needed
          if (subscriptions && subscriptions.tg_numberoflicences) {
            setLicenseInfo((prev) => ({
              ...prev,
              availableLicenses:
                subscriptions.tg_numberoflicences - formattedUsers.length,
            }));
          }
          
        } else {
          console.error("Invalid response format:", response);
          setError("Failed to load user list: Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching child contacts:", error);
        setError("Failed to load user list");
      } finally {
        setIsLoading(false);
      }
    };

    fetchChildContacts();
  }, [mainAccountContact?.contactid, subscriptions]);

  const PasswordRequirements = () => (
    <div
      className="password-requirements mt-2"
      style={{ fontSize: "0.85em", color: "#666" }}
    >
      <div className="alert alert-info">
        <strong>Password Requirements:</strong>
        <ul className="mb-0 mt-1">
          <li>Must be between 8 and 256 characters</li>
          <li>Must contain at least 3 of the following:</li>
          <ul>
            <li>Lowercase letters (a-z)</li>
            <li>Uppercase letters (A-Z)</li>
            <li>Numbers (0-9)</li>
            <li>
              Special characters (@ # $ % ^ & * - _ + = [ ] {} | \ : ' , ? / ` ~
              " ( ) ; .)
            </li>
          </ul>
        </ul>
      </div>
    </div>
  );

  const toggleUserForm = () => {
    setShowUserForm(!showUserForm);
    setFormData({ email: "", password: "", firstName: "", lastName: "" });
    setError("");
    setSuccess("");
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const addUser = async () => {
    setError("");
    setSuccess("");

    if (
      !formData.email ||
      !formData.password ||
      !formData.firstName ||
      !formData.lastName
    ) {
      setError(
        "Please provide all required fields: First Name, Last Name, Email, and Password"
      );
      return;
    }

    // License availability check
    if (licenseInfo.availableLicenses <= 0) {
      setError("No licenses available");
      return;
    }

    // Duplicate email check
    if (users.some((user) => user.email === formData.email)) {
      setError("User with this email already exists");
      return;
    }

    try {
      // Step 1: Create user in Azure B2C first (quick operation)
      //main account no need to run b2c
      if (formData.email !== mainAccountContact?.emailaddress1) {
        const b2cResponse = await createUser(
          formData.email,
          formData.password,
          formData.firstName,
          formData.lastName
        );

        if (b2cResponse.message !== "User created successfully") {
          throw new Error("Failed to create user in Azure B2C");
        }
      }

      // Step 2: If B2C successful, proceed with Dataverse operations
      const NewContact = {
        firstname: formData.firstName,
        lastname: formData.lastName,
        emailaddress1: formData.email,
        statecode: 0,  //active
        "tg_ParentContactID@odata.bind": `/contacts(${mainAccountContact.contactid})`,
      };

      const subscriptionData = {
        startdate: subscriptions.startdate,
        enddate: subscriptions.enddate,
        statecode: 0,
        emailaddress: formData.email,
        tg_numberoflicences: 1,
        isdefault: false,
        tg_subscriptionid: "151",
        'tg_Transactiondetails@odata.bind': `/tg_transactiondetails(${subscriptions._tg_transactiondetails_value})`,  //same as main account transaction details
      };

      const dataverseResponse = await apiService.createChildUser(
        NewContact,
        subscriptionData
      );

      if (!dataverseResponse.success && formData.email !== mainAccountContact?.emailaddress1) {
        // If Dataverse fails, delete the B2C user and throw error
        await deleteB2CUser(formData.email);
        throw new Error(
          "Failed to create user in Dataverse. Changes have been rolled back."
        );
      }

      // If both operations successful, update UI
      setUsers((prevUsers) => [
        ...prevUsers,
        {
          email: formData.email,
          firstName: formData.firstName,
          lastName: formData.lastName,
          contactId: dataverseResponse.data.contactId,
        },
      ]);
      setLicenseInfo((prev) => ({
        ...prev,
        availableLicenses: prev.availableLicenses - 1,
      }));
      setSuccess("User created successfully");
      setFormData({ email: "", password: "", firstName: "", lastName: "" });
      setShowUserForm(false);
    } catch (error) {
      console.error("Error in user creation:", error);
      setError(error.message || "Failed to create user");
    }
  };

  const deleteUser = async (userEmail, userContactId) => {
    if (!window.confirm(`Are you sure you want to delete user ${userEmail}?`)) {
      return;
    }

    setError("");
    setSuccess("");

    try {
      // Step 1: Delete from Dataverse first
      const isMainAccount = userEmail === mainAccountContact?.emailaddress1;
      const dataverseResponse = await apiService.deleteChildUser(
        userContactId,
        isMainAccount
      );

      if (!dataverseResponse.success) {
        throw new Error("Failed to delete user from Dataverse");
      }

      // // Step 2: If Dataverse deletion successful, delete from B2C
      // try {
      //   await deleteB2CUser(userEmail);
      // } catch (b2cError) {
      //   console.error(
      //     "B2C deletion failed, but Dataverse deletion was successful",
      //     b2cError
      //   );
      //   // Log this incident for manual cleanup of B2C later
      //   // You might want to implement a cleanup queue system for failed B2C deletions
      //   setError(
      //     "Warning: User removed from system but B2C cleanup failed. Please contact support."
      //   );
      // }

      // Update UI state
      setUsers((prevUsers) => prevUsers.filter((u) => u.email !== userEmail));
      setLicenseInfo((prev) => ({
        ...prev,
        availableLicenses: prev.availableLicenses + 1,
      }));

      setSuccess("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
      setError(error.message || "Failed to delete user");
    }
  };

  const subscribeMyself = async () => {
    try {

      const subscriptionData = {
        startdate: subscriptions.startdate,
        enddate: subscriptions.enddate,
        statecode: 0,
        emailaddress: formData.email,
        tg_numberoflicences: 1,
        isdefault: false,
        tg_subscriptionid: "151",
      };

      const response = await apiService.mainAccountSubscirbeMyself(mainAccountContact.contactid, { tg_flags: 9999 }, subscriptionData, );
      if (response.success) {
        setSuccess("Successfully subscribed yourself");
        if (subscriptions) {
          subscriptions.tg_flags = 9999;
        }
        setLicenseInfo(prev => ({
          ...prev,
          availableLicenses: prev.availableLicenses - 1
        }));
        setUsers(prevUsers => [...prevUsers, {
          email: mainAccountContact.emailaddress1,
          firstName: mainAccountContact.firstname,
          lastName: mainAccountContact.lastname,
          contactId: mainAccountContact.contactid,
        }]);

      } else {
        setError("Failed to subscribe: " + response.message);
      }
    } catch (error) {
      setError("Failed to subscribe: " + error.message);
      console.error("Error subscribing:", error);
    }
  }

  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <div role="tabpanel" className="tab-pane active" id="manageUsers">
          <span>
            Total licenses:{" "}
            <strong className="marginLeft3px badge" id="totalLicenses">
              {licenseInfo.totalLicenses}
            </strong>{" "}
            | Licenses available:{" "}
            <strong className="marginLeft3px badge" id="availableLicenses">
              {licenseInfo.availableLicenses}
            </strong>
          </span>

          {isLoading ? (
            <div className="loader"></div>
          ) : error ? (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          ) : (
            <table className="table table-bordered tablesorter" id="myTable">
              <thead className="thead_bg">
                <tr>
                  <th className="header">Email</th>
                  <th className="header">Name</th>
                  <th className="header"></th>
                </tr>
              </thead>
              <tbody>
                {users && users.length > 0 ? (
                  users.map((user, index) => (
                    <tr key={user.contactId || index}>
                      <td>{user.email || user.emailaddress1}</td>
                      <td>{`${user.firstName} ${user.lastName}`}</td>
                      <td>
                        <button
                          title="Delete User"
                          className="marginRight15px clearButtonStyle"
                          onClick={() => deleteUser(user.email, user.contactId)}
                        >
                          <i className="fa fa-trash-o"></i>
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="3" className="text-center">
                      No users found
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}

          {!showUserForm && licenseInfo.availableLicenses > 0 && (

            <p className="text-right noBtmMarging">
               {subscriptions?.tg_flags !== 9999 && (
                <button
                  type="button"
                  className="btn btn-primary btn-sm borderNone marginRight10"
                  onClick={() => subscribeMyself()}
                >
                  Subscribe myself
                </button>
              )}
              <button
                type="button"
                className="btn btn-info btn-sm borderNone"
                onClick={toggleUserForm}
              >
                Create User
              </button>
            </p>
          )}

          {error && (
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
          )}
          {success && (
            <div className="alert alert-success" role="alert">
              {success}
            </div>
          )}
          {showUserForm && (
            <div>
              <table className="table table-bordered">
                <thead className="thead_bg">
                  <tr>
                    <th style={{ width: "20%" }}>First Name</th>
                    <th style={{ width: "20%" }}>Last Name</th>
                    <th style={{ width: "30%" }}>Email</th>
                    <th style={{ width: "25%" }}>
                      Password
                      <i
                        className="fa fa-info-circle ml-2"
                        onMouseEnter={() => setShowPasswordRequirements(true)}
                        onMouseLeave={() => setShowPasswordRequirements(false)}
                        style={{ cursor: "help", marginLeft: "2px" }}
                      ></i>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="firstName"
                            value={formData.firstName || ""}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter first name"
                            required
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div className="form-group">
                          <input
                            type="text"
                            name="lastName"
                            value={formData.lastName || ""}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter last name"
                            required
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div>
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            value={formData.email || ""}
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter email"
                            required
                          />
                        </div>
                      </div>
                    </td>
                    <td>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <input
                            type="password"
                            name="password"
                            value={formData.password || ""} // Add fallback empty string
                            onChange={handleInputChange}
                            className="form-control"
                            placeholder="Enter password"
                            required
                          />
                        </div>
                        {showPasswordRequirements && <PasswordRequirements />}
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p className="text-right noBtmMarging">
                <button
                  type="button"
                  className="btn btn-default btn-sm marginRight10"
                  onClick={toggleUserForm}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-info btn-sm borderNone"
                  onClick={addUser}
                >
                  Add User
                </button>
              </p>
            </div>
          )}
        </div>
      </Suspense>
    </>
  );
};

export default ManageUsers;
