import React from "react";

const InputField = ({
  label,
  id,
  name,
  value,
  placeholder,
  onChange,
  type = "text",
  required = true,
}) => {
  return (
    <div className="form-group mTop12px">
      <label htmlFor={id} className="col-lg-4 col-md-4 col-sm-5 control-label">
        {label}:
        <span
          className="red"
          style={{ display: required ? "inline-block" : "none" }}
        >
          *
        </span>
      </label>
      <div className="col-lg-8 col-md-8 col-sm-7">
        <input
          type={type}
          name={name}
          id={id}
          className="form-control"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          required={required}
        />
      </div>
    </div>
  );
};

export default InputField;
