/* eslint-disable */
import React, {
  useState,
  useEffect,
  Suspense,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectID } from "../../features/counter/counterSlice";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import BillingInformationForm from "./checkoutForm/billingInformationForm";
import OrderSummaryForm from "./checkoutForm/orderSummaryForm";
import PaymentForm from "./checkoutForm/paymentForm";
import UpdateBillingAddressDialog from "./checkoutForm/updateBillingAdress";
import AccountCreationForm from "./checkoutForm/accountCreationForm";

import { apiService } from "../../api/dataverseApi";

const NavBar = React.lazy(() => import("../../components/navBar"));
const TopicBanner = React.lazy(() => import("../../components/topicBanner"));
const Footer = React.lazy(() => import("../../components/footer"));

const Checkout = (props) => {
  const { pathname, search, push } = props;
  const history = useHistory();
  const location = useLocation();
  const authID = useSelector(selectID);

  const [isExistingUser, setIsExistingUser] = useState(false);
  const [cartItem, setCartItem] = useState(() => {
    const stateCartItems = location.state?.cartItems;
    if (!stateCartItems) {
      console.log("No cart items found in location state");
    }
    return stateCartItems || null;
  });

  const [checkoutProcess, setCheckoutProcess] = useState("billingInformation");
  const [paymentType, setPaymentType] = useState(null);
  const [totalAmount, setTotalAmount] = useState(null);
  const [contactDetails, setContactDetails] = useState(null);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [billingAddress, setBillingAddress] = useState({});
  const [isBillingAddressDialogOpen, setBillingAddressDialogOpen] =
    useState(false);

  const [appliedCoupon, setAppliedCoupon] = useState(null);
  const [isProcessingPayment, setIsProcessingPayment] = useState(false);
  const [paymentError, setPaymentError] = useState(null);


  const isMountedRef = useRef(true);
  useEffect(() => {
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (authID.u && authID.u["signInNames.emailAddress"]) {
      setIsExistingUser(true);
    }
  }, [authID]);

  useEffect(() => {
    if (checkoutProcess === "payment") {
      const paymentElement = document.getElementById("paymentSummaryHeading");
      if (paymentElement) {
        paymentElement.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  }, [checkoutProcess]);

  const handleCouponApplied = (couponData) => {
    console.log("Coupon applied:", couponData);
    setAppliedCoupon(couponData);
  };

  const getDiscountDetails = (coupon) => {
    if (!coupon) return { DiscountRate: 0 };
    // discountType 1 = percentage, 2 = fixed amount
    if (coupon.discountType === 1) {
      return {
        DiscountRate: coupon.discountValue,
      };
    } else {
      return {
        DiscountRate: (coupon.discountValue / coupon.originalAmounts) * 100,
      };
    }
  };

  const activeCartType = useMemo(() => {
    return cartItem?.subscriptionid === "151" ? "single" : "multi";
  }, [cartItem]);

  const formatDateToMidnightUTC = (date) => {
    const d = new Date(date);
    d.setUTCHours(0, 0, 0, 0);
    return d.toISOString().replace(/\.000Z$/, "Z");
  };

  const openBillingAddressDialog = () => setBillingAddressDialogOpen(true);
  const closeBillingAddressDialog = () => setBillingAddressDialogOpen(false);

  const handlePriceUpdate = useCallback((price) => {
    if (typeof price === "number") {
      setTotalAmount(price);
    }
  }, []);

  const handleQuantityUpdate = (newQuantity) => {
    if (cartItem && cartItem.subscriptionid === "168") {
      setCartItem({
        ...cartItem,
        quantity: newQuantity,
      });
    }
  };

  const getTitleFromGenderCode = (gendercode) => {
    switch (gendercode) {
      case 1:
        return "Mr";
      case 2:
        return "Mrs";
      case 3:
        return "Dr";
      case 4:
        return "Prof";
      case 5:
        return "AProf";
      case 6:
        return "Ms";
      default:
        return "";
    }
  };

  const getXeroDescription = (cartItem) => {
    switch (cartItem.ordertype) {
      case "New":
        if (cartItem.subscriptionid === "151") {
          return "Therapeutic Guidelines - single user license (+Mobile App)";
        } else if (cartItem.subscriptionid === "168") {
          return "Therapeutic Guidelines - multi user license (+Mobile App)";
        }
        break;

      case "Renew":
        if (cartItem.subscriptionid === "151") {
          return "Therapeutic Guidelines renewal - single user license (+Mobile App)";
        } else if (cartItem.subscriptionid === "168") {
          return "Therapeutic Guidelines renewal - multi user license (+Mobile App)";
        }
        break;

      case "Addon":
        return "Therapeutic Guidelines extra licence (+Mobile App)";

      default:
        return "Therapeutic Guidelines Subscription (+Mobile App)";
    }
  };

  // The main "checkout" flow
  const handleCheckout = async ({ isFree = false, paymentInfo = null }) => {
    // 3. Check isMountedRef before setting state
    if (isMountedRef.current) {
      setIsProcessingPayment(true);
      setPaymentError(null);
    }

    try {
      const subscriptionData = {
        startdate: cartItem.startDate,
        enddate: cartItem.endDate,
        statecode: 0,
        statuscode: 0,
        emailaddress: isExistingUser
          ? authID.u["signInNames.emailAddress"]
          : newUserEmail,
        tg_numberoflicences: activeCartType === "multi" ? cartItem.quantity : 1,
        isdefault: false,
        _customerid_value: contactDetails.contactid,
        tg_subscriptionid: cartItem.subscriptionid,
        ...(appliedCoupon && {
          tg_couponreferrercode: appliedCoupon.couponCode,
        }),
      };

      const transactionData = {
        tg_actualamount: parseFloat(totalAmount),
        tg_orderdate: formatDateToMidnightUTC(cartItem.startDate),
        contactId: contactDetails.contactid,
        tg_ordertype: cartItem.ordertype || null,
        tg_subscriptionid: String(cartItem.subscriptionid),
        tg_transactionamount:
          appliedCoupon && appliedCoupon.originalAmounts
            ? parseFloat(appliedCoupon.originalAmounts)
            : parseFloat(totalAmount),
        tg_transactionresult: true, // success
        tg_transactionid: paymentInfo ? String(paymentInfo.transactionId) : null,
      };

      if (!isFree && paymentInfo) {
        transactionData.tg_paymentgatewayresponse = 2; // e.g., "completed"
        switch (paymentInfo.cardType) {
          case "AMEX":
            transactionData.tg_transactiontype = 1;
            break;
          case "MASTERCARD":
            transactionData.tg_transactiontype = 2;
            break;
          case "VISA":
            transactionData.tg_transactiontype = 3;
            break;
          case "PAYPAL":
            transactionData.tg_transactiontype = 4;
            break;
          default:
            // Default to PayPal if card type is not recognized
            transactionData.tg_transactiontype = 4;
        }
      }

      const xeroData = {
        paymentDetails: {
          type: paymentInfo?.paymentType,
          transactionId: paymentInfo?.transactionId || "",
          paymentDate: new Date().toISOString().split("T")[0],
        },
        contact: {
          Name: `${contactDetails.firstname} ${contactDetails.lastname}`,
          FirstName: contactDetails.firstname,
          LastName: contactDetails.lastname,
          EmailAddress: isExistingUser
            ? authID.u["signInNames.emailAddress"]
            : newUserEmail,
          Addresses: [
            {
              addressType: "POBOX",
              addressLine1: billingAddress.address1_line1,
              city: billingAddress.address1_city,
              region: billingAddress.address1_stateorprovince,
              postalCode: billingAddress.address1_postalcode,
              country: billingAddress.address1_country,
            },
          ],
        },
        invoice: {
          LineItems: [
            {
              Description: getXeroDescription(cartItem),
              Quantity: cartItem.quantity,
              UnitAmount: appliedCoupon
                ? appliedCoupon.originalAmounts / cartItem.quantity
                : totalAmount / cartItem.quantity,
              AccountCode: "200",
              TaxType: "OUTPUT",
              ...getDiscountDetails(appliedCoupon),
            },
          ],
        },
      };

      const totalnumberoflicences = Number(
        cartItem.quantity + (cartItem.currentmultilicensenumber || 0)
      );
      const updateSubscriptionData =
        cartItem.ordertype === "Addon" || cartItem.ordertype === "Renew"
          ? {
              subscriptiionType: cartItem.ordertype,
              parentEntitlementId: cartItem.currentsubscriptionid,
              ...(cartItem.ordertype === "Addon" && {
                quantity: totalnumberoflicences,
              }),
              ...(cartItem.ordertype === "Renew" && {
                enddate: cartItem.endDate,
              }),
            }
          : null;

      const checkoutResponse = await apiService.createCheckout({
        subscriptionData,
        transactionData,
        xeroData,
        paymentDetails: xeroData.paymentDetails,
        updateSubscriptionData,
        isComplimentary: isFree,
        cartId: cartItem.cartsid,
      });

      if (!checkoutResponse.success) {
        throw new Error(
          checkoutResponse.message || "Checkout processing failed"
        );
      }

      // Only navigate and set state if still mounted
      if (isMountedRef.current) {
        const orderDetails = {
          status: "COMPLETED",
          create_time: new Date().toISOString(),
          contactInfo: {
            title: getTitleFromGenderCode(contactDetails.gendercode),
            firstName: contactDetails.firstname,
            lastName: contactDetails.lastname,
            company: contactDetails.companyname || "",
            address: {
              street: billingAddress.address1_line1,
              city: billingAddress.address1_city,
              state: billingAddress.address1_stateorprovince,
              postalCode: billingAddress.address1_postalcode,
              country: billingAddress.address1_country,
            },
          },
          items: [
            {
              description: `Therapeutic Guidelines${cartItem.ordertype} ${
                activeCartType === "single" ? "1 user" : "Multi user"
              } Subscription`,
              validTo: cartItem.endDate,
              price: isFree ? 0 : totalAmount,
              quantity: cartItem.quantity,
            },
          ],
          receiptDetails: {
            receiptNumber: checkoutResponse.data.receiptNumber,
            paymentDate: new Date().toISOString(),
            purchaseOrderNumber: checkoutResponse.data.purchaseOrderNumber,
          },
          payment: {
            total: isFree ? 0 : totalAmount,
          },
        };

        history.push("/checkout/orderConfirmation", {
          state: { orderDetails },
        });
      }
      return;
    } catch (error) {
      console.error("Error processing checkout:", error);
      // Only update state if still mounted
      if (isMountedRef.current) {
        setPaymentError(
          error.message ||
            "Failed to complete order processing. Please contact support."
        );
      }
    } finally {
      if (isMountedRef.current) {
        setIsProcessingPayment(false);
      }
    }
  };

  const handlePaymentSuccess = async (paymentInfo) => {
    // Because this might still fire after unmount, always check
    if (!isMountedRef.current) return;
    console.log("paymentInfo", paymentInfo);
   // console.log("paymentType", paymentType);
    await handleCheckout({ isFree: false, paymentInfo });
  };

  const handleFreeCheckout = async () => {
    if (!isMountedRef.current) return;
    await handleCheckout({ isFree: true });
  };

  const handlePaymentError = async (error) => {
    console.error("Payment error:", error);
    // Only set error state if mounted
    if (isMountedRef.current) {
      setPaymentError(error.message || "Payment failed. Please try again.");
      setIsProcessingPayment(false);
    }
    try {
      const failureResponse = await apiService.createFailureTransaction({
        contactId: contactDetails.contactid,
        tg_transactionresult: false, // failure
        tg_responsemessage: error.message || "Payment processing failed",
        tg_responsecode: error.code || "PAYMENT_ERROR",
        tg_actualamount: parseFloat(totalAmount),
        tg_orderdate: formatDateToMidnightUTC(new Date()),
      });
      console.log("failureResponse", failureResponse.tg_ordernumber);

      // Navigate only if still mounted
      if (isMountedRef.current) {
        history.push("/checkout/orderConfirmation", {
          state: {
            orderDetails: {
              success: false,
              status: "FAILED",
              orderNumber: failureResponse.tg_ordernumber,
              referenceNumber: failureResponse.tg_invoicenumber,
            },
          },
        });
      }
    } catch (failureError) {
      console.error("Error recording failure transaction:", failureError);
    }
  };

  const handleSaveBillingAddress = (newAddress) => {
    if (!isMountedRef.current) return;
    const updatedAddress = {
      ...newAddress,
      firstname: billingAddress.firstname,
      lastname: billingAddress.lastname,
    };
    setBillingAddress(updatedAddress);
  };

  useEffect(() => {
    if (checkoutProcess === "orderSummary") {
      const address = {
        firstname: contactDetails.firstname,
        lastname: contactDetails.lastname,
        address1_line1: contactDetails.address1_line1,
        address1_city: contactDetails.address1_city,
        address1_stateorprovince: contactDetails.address1_stateorprovince,
        address1_postalcode: contactDetails.address1_postalcode,
        address1_country: contactDetails.address1_country,
      };
      setBillingAddress(address);
    }
  }, [checkoutProcess, contactDetails]);

  const nextSteps = () => {
    switch (checkoutProcess) {
      case "billingInformation":
        isExistingUser
          ? setCheckoutProcess("orderSummary")
          : setCheckoutProcess("accountCreation");
        break;
      case "accountCreation":
        setCheckoutProcess("orderSummary");
        break;
      case "orderSummary":
        setCheckoutProcess("payment");
        break;
      default:
        break;
    }
  };

  const prevSteps = () => {
    switch (checkoutProcess) {
      case "orderSummary":
        isExistingUser
          ? setCheckoutProcess("billingInformation")
          : setCheckoutProcess("accountCreation");
        break;
      case "payment":
        setCheckoutProcess("orderSummary");
        break;
      case "accountCreation":
        setCheckoutProcess("billingInformation");
        break;
      default:
        break;
    }
  };

  const updatePaymentType = (data) => setPaymentType(data);

  return (
    <Suspense fallback={<div className="loader"></div>}>
      <NavBar push={push} />
      <TopicBanner pathname={pathname} search={search} push={push} />

      {cartItem && (
        <div className="container checkout" id="wrap">
          <div className="col-lg-12 margintp-30">
            <div className="col-lg-7 col-sm-7 col-md-7 col-xs-12 no_left_padding leftBox-xs rightalign">
              <div className="panel-group secureCheckOut" id="accordion">
                <div
                  className="panel-heading checkout-panel-heading"
                  role="tab"
                  id="headingOne"
                  style={{
                    borderBottom:
                      checkoutProcess === "billingInformation"
                        ? "4px solid #1aa2a0"
                        : "1px solid #1aa2a0",
                  }}
                >
                  <h4 className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      1. Billing Information{" "}
                    </a>
                  </h4>
                </div>

                {checkoutProcess === "billingInformation" && (
                  <BillingInformationForm
                    nextSteps={nextSteps}
                    contactDetails={contactDetails}
                    setContactDetails={setContactDetails}
                  />
                )}

                {!isExistingUser && (
                  <div
                    className="panel-heading checkout-panel-heading"
                    role="tab"
                    id="headingOne"
                    style={{
                      borderBottom:
                        checkoutProcess === "accountCreation"
                          ? "4px solid #1aa2a0"
                          : "1px solid #1aa2a0",
                    }}
                  >
                    <h4 className="panel-title">
                      <a
                        role="button"
                        data-toggle="collapse"
                        data-parent="#accordion"
                        href="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        2. Account Creation
                      </a>
                    </h4>
                  </div>
                )}
                {checkoutProcess === "accountCreation" && !isExistingUser && (
                  <AccountCreationForm
                    nextSteps={nextSteps}
                    contactDetails={contactDetails}
                    setNewUserEmail={setNewUserEmail}
                  />
                )}

                <div
                  className="panel-heading checkout-panel-heading"
                  role="tab"
                  id="headingOne"
                  style={{
                    borderBottom:
                      checkoutProcess === "orderSummary"
                        ? "4px solid #1aa2a0"
                        : "1px solid #1aa2a0",
                  }}
                >
                  <h4 className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {isExistingUser ? 2 : 3}. Order summary{" "}
                    </a>
                  </h4>
                </div>

                {(checkoutProcess === "orderSummary" ||
                  checkoutProcess === "payment") && (
                  <OrderSummaryForm
                    cartItem={cartItem}
                    updatePaymentType={updatePaymentType}
                    onPriceUpdate={handlePriceUpdate}
                    onQuantityUpdate={handleQuantityUpdate}
                    onCouponApplied={handleCouponApplied}
                    onProcessFreeCheckout={handleFreeCheckout}
                    nextSteps={nextSteps}
                  />
                )}

                <div
                  className="panel-heading checkout-panel-heading"
                  role="tab"
                  id="headingOne"
                  style={{
                    borderBottom:
                      checkoutProcess === "payment"
                        ? "4px solid #1aa2a0"
                        : "1px solid #1aa2a0",
                  }}
                >
                  <h4 className="panel-title">
                    <a
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseOne"
                      aria-expanded="true"
                      aria-controls="collapseOne"
                    >
                      {" "}
                      {isExistingUser ? 3 : 4}. Summary{" "}
                    </a>
                  </h4>
                </div>

                {paymentType !== null && (
                  <PaymentForm
                    paymentType={paymentType}
                    totalAmount={Number(totalAmount)}
                    onPaymentSuccess={handlePaymentSuccess}
                    onPaymentError={handlePaymentError}
                    isProcessing={isProcessingPayment}
                  />
                )}
              </div>
            </div>

            <div className="col-lg-5 col-sm-5 col-md-5 col-xs-12 margintop10px rightBox-xs leftBox-xs leftAlign">
              <h4 className="checkout-procress section-heading hidden-xs">
                Your checkout progress
              </h4>
              <ul className="step_listing">
                <li>
                  <a
                    href="#"
                    className={
                      checkoutProcess === "billingInformation" ? "active" : ""
                    }
                    id="billingInformationHeading"
                  >
                    <span
                      className={
                        checkoutProcess === "billingInformation" ? "active" : ""
                      }
                    >
                      1
                    </span>
                    Billing information
                  </a>
                </li>
                {!isExistingUser && (
                  <li>
                    <a
                      href="#"
                      className={
                        checkoutProcess === "accountCreation" ? "active" : ""
                      }
                      id="accountCreationHeading"
                    >
                      <span
                        className={
                          checkoutProcess === "accountCreation" ? "active" : ""
                        }
                      >
                        2
                      </span>
                      Account creation
                    </a>
                  </li>
                )}
                <li>
                  <a
                    href="#"
                    className={
                      checkoutProcess === "orderSummary" ? "active" : ""
                    }
                    id="orderSummaryHeading"
                  >
                    <span
                      className={
                        checkoutProcess === "orderSummary" ? "active" : ""
                      }
                    >
                      {isExistingUser ? 2 : 3}
                    </span>
                    Order summary
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className={checkoutProcess === "payment" ? "active" : ""}
                    id="paymentSummaryHeading"
                  >
                    <span
                      className={checkoutProcess === "payment" ? "active" : ""}
                    >
                      {isExistingUser ? 3 : 4}
                    </span>
                    Payment
                  </a>
                </li>
              </ul>

              {checkoutProcess === "orderSummary" && (
                <div className="row">
                  <div
                    className="col-lg-6 col-xs-12 addBoxHeight"
                    id="newUserAddressLocation"
                  >
                    Billing adress
                    <div className="panel adrs-info margin_btm30px">
                      <div className="adress_box">
                        <a
                          className="pull-right"
                          onClick={openBillingAddressDialog}
                        >
                          <i className="fa fa-edit font20p edit_icon"></i>
                        </a>
                        <UpdateBillingAddressDialog
                          isOpen={isBillingAddressDialogOpen}
                          onClose={closeBillingAddressDialog}
                          billingAddress={billingAddress}
                          onSave={handleSaveBillingAddress}
                        />
                        <div className="panel-body">
                          <address>
                            <strong>
                              <span id="givenName_new">
                                {billingAddress.firstname}
                              </span>
                              &nbsp;
                              <span id="surName_new">
                                {billingAddress.lastname}
                              </span>
                            </strong>
                            <br />
                            <span id="streetAddress_new">
                              {billingAddress.address1_line1}
                            </span>
                            <br />
                            <span id="suburb_new">
                              {billingAddress.address1_city}
                            </span>
                            <br />
                            <span id="state_new">
                              {billingAddress.address1_stateorprovince}
                            </span>
                            &nbsp;
                            <span id="postalCode_new">
                              {billingAddress.address1_postalcode}
                            </span>
                            &nbsp;
                            <span id="country_new">
                              {billingAddress.address1_country}
                            </span>
                          </address>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {!cartItem && (
        <div className="container">
          <p>
            There are no items to check out. Please{" "}
            <button
              className="btn btn-info"
              style={{
                backgroundColor: "#1aa2a0",
                borderColor: "#188f8e",
                color: "#ffffff",
                fontWeight: "600",
                borderRadius: "2px",
              }}
              onClick={() => push("/myCart")}
            >
              Go to your Cart
            </button>{" "}
            before proceeding.
          </p>
        </div>
      )}

      <Footer />
    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(Checkout);
