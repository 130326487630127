/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { useSelector, useDispatch } from "react-redux";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import CardForm from "../payment/cardForm";
import PayPalLoginButton from "../payment/payPalLoginButton";

const PaymentForm = (props) => {
  const {
    paymentType,
    totalAmount,
    //  billingAddress,
    // email,
    onPaymentSuccess,
    onPaymentError,
    isProcessing,
  } = props;

  return (
    <>
      <div className="payment-form">
        <h4 className="payment-form-title">Invoice payment</h4>
        {paymentType === "cc" && (
          <div className="payment-detail-heading">
            <b>Enter Your Payment Details</b>
          </div>
        )}

        <div className="payment-amount-box">
          <div className="mTop12px text-right">
            <div className="bg-amount">
              <strong>
                <b>Amount</b>
              </strong>
              <span id="finalPricepp"> &nbsp;{totalAmount.toFixed(2)}</span>
              <span>&ensp;AUD</span>
            </div>
          </div>
        </div>
        {isProcessing && (
          <div className="text-center">
            <p>Processing your payment. Please wait...</p>
          </div>
        )}

        {paymentType === "cc" && (
          <CardForm
            onPayment={onPaymentSuccess}
            onError={onPaymentError}
            amount={totalAmount.toFixed(2)}
          />
        )}

        {paymentType === "paypal" && (
          <div className="by-payPal">
            <PayPalLoginButton
              onPayment={onPaymentSuccess}
              onError={onPaymentError}
              amount={totalAmount.toFixed(2)}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps)(PaymentForm);
