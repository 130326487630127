export const countries = [
  "Australia",
  "Algeria",
  "Angola",
  "Anguilla",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Ascension and St Helena",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belarus",
  "Belgium",
  "Belize",
  "Benin",
  "Bermuda",
  "Bhutan",
  "Bolivia",
  "Bosnia-Herzegovina",
  "Botswana",
  "Brazil",
  "British Indian Ocean Territory",
  "Brunei Darussalam",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Cape Verde",
  "Cayman Islands",
  "Central African Republic",
  "Chad",
  "Chile",
  "China, People's Republic",
  "Colombia",
  "Comoros",
  "Congo",
  "Congo, Democratic Republic Of",
  "Cook Islands",
  "Costa Rica",
  "CÔTe D'Ivoire",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "East Timor",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Falkland Islands",
  "Fiji",
  "Finland",
  "France",
  "French Guiana",
  "French Polynesia",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Gibraltar",
  "Greece",
  "Greenland",
  "Grenada",
  "Guadeloupe",
  "Guam",
  "Guatemala",
  "Guinea",
  "Guinea-Bissau",
  "Guyana",
  "Haiti",
  "Honduras",
  "Hong Kong",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iran, Islamic Republic",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Jordan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Korea, Democratic People's Republic",
  "Korea, Republic",
  "Kuwait",
  "Kyrgyzstan",
  "Lao, People's Democratic Republic",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libyan Arab Jamahiriya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Macao",
  "Macedonia",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Mariana Islands",
  "Marshall Islands",
  "Martinique",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia, Federated States Of",
  "Moldova",
  "Mongolia",
  "Montserrat",
  "Morocco",
  "Mozambique",
  "Myanmar",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "Netherlands Antilles And Aruba",
  "New Caledonia",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "Niue Island",
  "Norway",
  "Oman",
  "Pakistan",
  "Palau, Republic Of",
  "Panama, Republic Of",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Pitcairn Islands",
  "Poland, Republic Of",
  "Portugal",
  "Puerto Rico",
  "Qatar",
  "Reunion",
  "Romania",
  "Russian Federation",
  "Rwanda",
  "Saint Christopher (St Kitts) And Nevis",
  "Samoa, American",
  "Samoa, Western",
  "Sao Tome And Principe",
  "Saudi Arabia, Kingdom Of",
  "Senegal",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Solomon Islands",
  "Somalia",
  "South Africa",
  "Spain",
  "Sri Lanka",
  "St Lucia",
  "St Pierre And Miquelon",
  "St Vincent And The Grenadines",
  "Sudan",
  "Suriname",
  "Swaziland",
  "Sweden",
  "Switzerland",
  "Syrian Arab Republic",
  "Taiwan",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Togo",
  "Tokelau Islands",
  "Tonga",
  "Trinidad And Tobago",
  "Tristan Da Cunha",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Turks And Caicos Islands",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States Of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vatican",
  "Venezuela",
  "Vietnam",
  "Virgin Islands, British",
  "Virgin Islands, USA",
  "Wallis And Futuna Islands",
  "Yemen",
  "Yugoslavia",
  "Zambia",
  "Zimbabwe",
];

export const states = [
  "ACT",
  "NSW",
  "NT",
  "QLD",
  "SA",
  "TAS",
  "VIC",
  "WA",
  "Outside Australia",
];

export const customerTypes = [
  { text: "Individual", value: 1 },
  { text: "Hospital", value: 2 },
  { text: "Institution user", value: 3 },
  { text: "Organisation", value: 4 },
];

export const title = [
  { text: "Mr", value: 1 },
  { text: "Mrs", value: 2 },
  { text: "Dr", value: 3 },
  { text: "Prof", value: 4 },
  { text: "AProf", value: 5 },
  { text: "Ms", value: 6 },
];

export const workTypeOptions = {
  1: [
    "General Practitioner/General Practice",
    "GP Training Organisations",
    "GPRegistrars",
    "Hospital Intern or Resident",
    "Nurse",
    "Other",
    "Pharmacist/Pharmacy",
    "Specialist",
    "Undergraduate",
  ],
  4: [
    "Bookshops",
    "Community Services",
    "General Practitioner/General Practice",
    "Government Departments",
    "Nursing home/Aged Care",
    "Other Organisation",
    "Pharmaceutical Organisation",
    "Pharmacist/Pharmacy",
    "Universities",
  ],
  2: ["Private", "Public"],
  3: [
    "General Practitioner/General Practice",
    "GP Training Organisations",
    "GPRegistrars",
    "Hospital Intern or Resident",
    "Nurse",
    "Other",
    "Pharmacist/Pharmacy",
    "Specialist",
    "Undergraduate",
  ],
};
