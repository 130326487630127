const MAILCHIMP_URL = "https://www.us4.list-manage.com/subscribe/post-json";
const U_ID = "c5a2234232956af25270a94e2";
const FORM_ID = "368f844a90";

export const subscribeToNewsletter = (email) => {
  return new Promise((resolve, reject) => {
    const scriptId = "mailchimp-jsonp";
    // Remove any existing JSONP script
    const oldScript = document.getElementById(scriptId);
    if (oldScript) {
      document.body.removeChild(oldScript);
    }

    // Create script element
    const script = document.createElement("script");
    script.id = scriptId;

    const url = new URL(MAILCHIMP_URL);
    url.searchParams.append("c", "callback");
    url.searchParams.append("u", U_ID);
    url.searchParams.append("id", FORM_ID);
    url.searchParams.append("EMAIL", email);

    window.callback = (response) => {
      document.body.removeChild(script);
      if (response.result === "success") {
        resolve(response);
      } else {
        reject(new Error(response.msg));
      }
      delete window.callback;
    };

    script.src = url.toString();
    document.body.appendChild(script);
  });
};
