import { useState, useEffect } from 'react';

const useScreenSize = () => {
  const [isWebScreen, setIsWebScreen] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsWebScreen(window.innerWidth >= 1024);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return isWebScreen;
};

export default useScreenSize;