/* eslint-disable */
import React, { useState, Suspense } from "react";
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { myCartLoginRequest } from "../../authConfig";

const NavBar = React.lazy(() => import("../../components/navBar"));
const TopicBanner = React.lazy(() => import("../../components/topicBanner"));
const Footer = React.lazy(() => import("../../components/footer"));

const UserConfirmation = (props) => {
  const { pathname, search, push } = props;
  const { instance} = useMsal();
  const location = useLocation();
  const [cartItems, setCartItem] = useState(location.state?.cartItems);

    const handleGoToCheckout = (e) => {
      if (!cartItems) {
        alert("No items in cart. Redirecting to cart page.");
        push("/myCart");
        return;
      }
    push({
      pathname: "/checkout",   state: {cartItems: cartItems}});
  };


  const handleLogin =async (e) => {
    e.preventDefault();
    try{
      // 1) Grab the cart items from location.state
      const cartItems = location?.state?.cartItems || [];

      // 2) Stringify them in your custom state object
      const stateObj = JSON.stringify({
        redirectTo: "/checkout",
        cartItems: cartItems
      });
  

    await instance.loginRedirect({
        ...myCartLoginRequest,
        state: location?.state?.cartItems || [],
        // redirectUri: window.location.origin + "/checkout"
         redirectStartPage: "/myCart" ,
        // state: stateObj
      });
 
    }catch(err){
      console.log("login error in user confirmation page",err);
    }
      
  };

  return (
    <>
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <TopicBanner pathname={pathname} search={search} push={push} />
        <div className="container userConfirmation" style={{height:"700px"}}>
          <div className="col-lg-12 margintp-30">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_left_padding noRightPaddingxs">
              <h4 className="section-heading">Returning subscribers</h4>
              {/* <div className="customer_box">
                <h5 className="section-subheading" title="">
                  Sign in
                </h5>
                <p>
                  If you have an account, please log in for faster checkout.
                </p>
                <form id="loginbtnfrm" className="loginForm">
                  <div className="form-group">
                    <label htmlFor="email1">Email</label>
                    <input
                      type="text"
                      className="form-control"
                      name="user.username"
                      id="userName"
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="form-group mTop12px">
                    <label for="password1">Password</label>
                    <input
                      type="password"
                      name="user.password form-control"
                      id="pwd"
                      className="form-control"
                      placeholder="Password"
                      required
                    />
                  </div>
                  <h5 className="forgot-head">
                    <a
                      data-target="#forgotpwdBox"
                      data-backdrop="static"
                      data-keyboard="false"
                      data-toggle="modal"
                      href="#"
                      className="underline"
                      title="Forgot your password"
                    >
                      Forgot your password?
                    </a>
                  </h5>
                  <a
                    id="loginId"
                    // href="#"
                    className="btn btn-sm btn-info"
                    onClick={handleGoToCheckout}
                  >
                    Continue to checkout
                  </a>
                </form>
              </div> */}
              <h5 className="section-subheading" title="">
                click here to sign in
              </h5>
              <a
                id="checkoutId"
                // href="#"
                className="btn btn-sm btn-info"
                onClick={handleLogin}
              >
                Sign in
              </a>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 no_left_padding noRightPaddingxs">
              <h4 className="section-heading">New subscriber</h4>
              <h5 className="section-subheading" title="">
                Create an account to purchase.
              </h5>
              <a
                id="checkoutId"
                // href="#"
                className="btn btn-sm btn-info"
                onClick={handleGoToCheckout}
              >
                Continue to checkout
              </a>
            </div>
          </div>
        </div>
        <Footer />
      </Suspense>
    </>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(UserConfirmation);
