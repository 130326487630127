const BASE_URL = process.env.REACT_APP_BASE_URL;
//console.log("BASE_URL", BASE_URL);
const DEFAULT_HEADERS = {
  "Content-Type": "application/json",
};

async function apiRequest(
  endpoint,
  method = "GET",
  body = null,
  queryParams = {}
) {
  const config = {
    method,
    headers: DEFAULT_HEADERS,
  };
  if (body) {
    config.body = JSON.stringify(body);
  }

  let url = `${BASE_URL}/${endpoint}`;
  const queryString = new URLSearchParams(queryParams).toString();
  if (queryString) {
    url += `?${queryString}`;
  }

  console.log(`API request: ${method} ${url}`);
  const response = await fetch(url, config);
  console.log("raw response", response);
  if (!response.ok) {
    throw new Error(`API call failed: ${method} ${endpoint}`);
  }

  const textResponse = await response.text();
  try {
    return JSON.parse(textResponse);
  } catch (error) {
    console.error("Failed to parse JSON response:", textResponse);
    return { success: false, message: "Failed to parse server response" };
  }
}

export const apiService = {
  getCart: () => {
    const token = localStorage.getItem("tgCartToken");
    if (!token) {
      console.log("no cart token found");
      return [];
    }
    return apiRequest("cartApi", "GET", null, { token });
  },

  addToCart: (item) => {

    return apiRequest("cartApi", "POST", {
      token: item.token,
      subscriptionid: item.subscriptionid,
      quantity: item.quantity,
      ordertype: item.ordertype,
      enddate: item.enddate,
      startdate: item.startdate,
      currentSubscriptionId: item.currentSubscriptionId,
      currentmultilicensenumber: item.currentmultilicensenumber,
      hasappliedcoupon:item.hasappliedcoupon,
    });
  },

  updateCartItem: async (item) => {
    try {
      const response = await apiRequest(
        `cartApi`,
        "PUT",
        { quantity: item.quantity },
        { cartsId: item.cartsid }
      );
      return response;
    } catch (error) {
      console.error("Error updating cart item:", error);
      return {
        success: false,
        message: "Failed to update cart item",
        error: error.message,
      };
    }
  },

  deleteCartItem: (CartsId) => {
    const token = localStorage.getItem("tgCartToken");
    if (!token) {
      throw new Error("No cart found");
    }

    return apiRequest("cartApi", "DELETE", null, {
      cartsId: CartsId,
      token,
    });
  },

  getContact: (emailaddress1) => {
   console.log("emailaddress1", emailaddress1);
    return apiRequest("ContactsApi", "GET", null, { emailaddress1 });
  },

  getChildContacts: (parentContactId) => {
   // console.log("Fetching child contacts for parent:", parentContactId);
    return apiRequest("ContactsApi", "GET", null, { parentContactId });
  },

  deleteChildUser: (contactId, ismainaccount) => {
    // console.log(
    //   "Deleting child user:",
    //   contactId,
    //   "Parent subscription:",
    //   licensesData
    // );
    return apiRequest("ManageUser", "DELETE", {
      contactId,
      ismainaccount
    });
  },
  
  mainAccountSubscirbeMyself: (contactId, flags, subscriptionData) => {
    return apiRequest(
      "ManageUser", 
      "PUT",
      {
        contactId: contactId,
        flags,
        subscriptionData
      },
    );
  },

  createContact: (data) => {
   // console.log(" create contact data in dataverserapi ", data);
    return apiRequest("ContactsApi", "POST", {
      data,
    });
  },
  createFailureTransaction: (data) => {
   // console.log("Creating transaction with data:", data);
    return apiRequest("TransactionApi", "POST", data);
  },

  createSubscription: (data) => {
   // console.log(" create subscription data in dataverserapi ", data);
    return apiRequest("SubscriptionApi", "POST", {
      data,
    });
  },
  applyCoupon: (data) => {
  //  console.log(" apply coupon in dataverserapi ", data);
    return apiRequest("CouponApi", "PUT", {
      data,
    });
  },
  getUserProfile: (email) => {
    return apiRequest("SubscriptionApi", "GET", null, { email });
  },

  createChildUser: (NewContact, subscriptionData, licensesData) => {
  
    return apiRequest("ManageUser", "POST", {
      NewContact,
      subscriptionData,
    });
  },

  createCheckout: (data) => {
    const requestBody = {
      subscriptionData: data.subscriptionData,
      transactionData: data.transactionData,
      paymentDetails: data.paymentDetails,
      isComplimentary: data.isComplimentary || false,
      cartId: data.cartId
    };
    // Only include xeroData for paid checkouts
    if (!data.isComplimentary && data.xeroData) {
      requestBody.xeroData = data.xeroData;
    }
    // Include AddOnSubscriptionData if provided

    if (data.updateSubscriptionData) {
      requestBody.updateSubscriptionData = data.updateSubscriptionData;
    }

    return apiRequest("CheckoutApi", "POST", requestBody);
  },

  UserRegistrationApi: (data) => {
   // console.log("Processing checkout with data:", data);
    return apiRequest("UserRegistrationApi", "POST", {
      email:data.email,
      password:data.password,
      firstName:data.firstName,
      lastName:data.lastName,
      contactId:data.contactId
    });
  },
  exchangeXeroAuthorizationCode: (code) => {
    return apiRequest("XeroSetup", "POST", { code });
  },
  getXeroAccounts: () => {
    // GET request; no body needed
    return apiRequest("XeroSetup", "GET");
  },
  sendMailEvent: (emailData) => {
    return apiRequest("mailEvent", "POST", emailData);
  },
};
