/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { push } from "connected-react-router";
import { connect } from "react-redux";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { apiService } from "../../../api/dataverseApi";

const NavBar = React.lazy(() => import("../../../components/navBar"));
const TopicBanner = React.lazy(() => import("../../../components/topicBanner"));
const Footer = React.lazy(() => import("../../../components/footer"));

const XeroAuthResponse = (props) => {
  const { pathname, search, push, location } = props;
  const [tokenSet, setTokenSet] = useState(null);

  useEffect(() => {
    const handleOAuthCallback = async () => {
      const params = new URLSearchParams(location.search);
      const code = params.get("code");

      if (code) {
        // Exchange the authorization code for tokens via your backend
        try {
          const response = await apiService.exchangeXeroAuthorizationCode(code);
          //console.log(" xero auth Response:", response);
            setTokenSet(response);
         
        } catch (error) {
          console.error("Failed to store Xero token set:", error);
        }
      } else {
        console.log("No authorization code found in URL.");
      }
    };

    handleOAuthCallback();
  }, [location]);

  return (
      <Suspense fallback={<div className="loader"></div>}>
        <NavBar push={push} />
        <TopicBanner pathname={pathname} search={search} push={push} />
        <MsalAuthenticationTemplate>
          <div className="container" style={{ height: "50vh" }}>
            Please check in dataverse for the token set below:
            {tokenSet && (
              <div>
                <p>Access Token: {tokenSet.access_token}</p>
                <p>Refresh Token: {tokenSet.refresh_token}</p>
                <p>Id Token: {tokenSet.id_token}</p>
                <p> expires at: {tokenSet.expires_at}</p>
                <p>Scope: {tokenSet.scope}</p>
                <p> Token type: {tokenSet.token_type}</p>
              </div>
            )}
          </div>
        </MsalAuthenticationTemplate>
        <Footer />
      </Suspense>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(XeroAuthResponse);
