/* eslint-disable */
import React, { useState, useEffect, Suspense } from "react";
import { connect, useSelector, useDispatch } from "react-redux";
import { selectID } from "../../features/counter/counterSlice";
import { push } from "connected-react-router";
import {
  updateSingleSubscription,
  updateMultiUserSubscription,
} from "../../store/reducers/subscripion";
import { apiService } from "../../api/dataverseApi";
import axios from "axios";

const Purchase = (props) => {
  const { push } = props;
  const authID = useSelector(selectID);
  const [purchaseOption, setPurchaseOption] = useState("singleUser");
  const [quantity, setQuantity] = useState(2);
  const [error, setError] = useState("");
  const [emailData, setEmailData] = useState({
    to: "",
    subject: "Institution license enquiry",
    name: "",
    contactNumber: "",
    businessName: "",
    message: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState({ type: '', message: '' });

  const unitCost = process.env.REACT_APP_MULTI_USER_PRICE;
  const dispatch = useDispatch();

  function generateToken(length = 32) {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let token = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      token += characters[randomIndex];
    }
    return token;
  }
  const handleRadioChange = (value) => {
    setPurchaseOption(value);
  };
  const handleQuantityChange = (e) => {
    setQuantity(parseInt(e.target.value));
  };

  const checkSubscriptionStatus = async (email) => {
    try {
      const response = await apiService.getUserProfile(email);
      if (response.success && response.data?.subscriptions) {
        const { enddate, tg_couponreferrercode } = response.data.subscriptions;
        const endDate = new Date(enddate);
        const threeMonthsFromEnd = new Date(endDate);
        threeMonthsFromEnd.setMonth(threeMonthsFromEnd.getMonth() + 3);
  
        const today = new Date();
        const hasAppliedCoupon = tg_couponreferrercode !== null;
  
        if (today < threeMonthsFromEnd) {
          return {
            canPurchase: false,
            message: "You have an active subscription. Please visit your profile page to renew.",
            hasAppliedCoupon,
          };
        }
        return { canPurchase: true, message: "", 
          hasAppliedCoupon 
        };
      }
      return { canPurchase: true, message: "", hasAppliedCoupon: false };
    } catch (error) {
      console.error("Failed to check subscription status:", error);
      return {
        canPurchase: true,
        message: "Unable to verify subscription status. Proceeding with purchase.",
        hasAppliedCoupon: false,
      };
    }
  };
  const handleAddToCart = async (e) => {
    e.preventDefault();
  
    const email = authID?.u?.["signInNames.emailAddress"];
 let subscriptionStatus = { canPurchase: true, hasAppliedCoupon: false };
    if (email) {
      subscriptionStatus = await checkSubscriptionStatus(email);
      if (!subscriptionStatus.canPurchase) {
        alert(subscriptionStatus.message);
        push("/showMyProfile");
        return;
      }
    }
  
    const token = localStorage.getItem("tgCartToken") || generateToken();
    localStorage.setItem("tgCartToken", token);
  
    const newItem = {
      token,
      subscriptionid: purchaseOption === "singleUser" ? "151" : "168",
      quantity: purchaseOption === "multipleUser" ? quantity : 1,
      hasappliedcoupon: subscriptionStatus.hasAppliedCoupon,
    };
    try {
      const response = await addToCartApi(newItem);
      if (response?.success) {
        console.log(response.message);
        setTimeout(() => push("/myCart"), 2000);
      } else {
        throw new Error(response?.message || "Failed to add item to cart");
      }
    } catch (error) {
      console.error("Failed to add item to cart:", error);
      alert(error.message || "An error occurred while adding the item to the cart.");
    }
  };

  const addToCartApi = async (item) => {
    try {
      const response = await apiService.addToCart(item);
      return response;
    } catch (error) {
      console.error("Failed to add item to cart:", error);
    }
  };

  const handleChange = (e) => {
    console.log(e.target.name, e.target.value);
    setEmailData({ ...emailData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    setError('');
    setSubmitStatus({ type: '', message: '' });
    
    const { name, to, contactNumber, businessName } = emailData;

    if (!name || !to || !contactNumber || !businessName) {
        setError("Please fill in all required fields.");
        return;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(to)) {
        setError("Please enter a valid email address.");
        return;
    }

    const phoneRegex = /^[0-9()+\- ]{8,}$/;
    if (!phoneRegex.test(contactNumber)) {
        setError("Please enter a valid phone number.");
        return;
    }

    setIsSubmitting(true);

    try {
        const response = await apiService.sendMailEvent(emailData);
        if (response.success) {
            setSubmitStatus({
                type: 'success',
                message: 'Thank you for your enquiry. A confirmation email has been sent to your email address.'
            });

            setEmailData({
                to: "",
                subject: "Institution license enquiry",
                name: "",
                contactNumber: "",
                businessName: "",
                message: ""
            });
        }
    } catch (error) {
        const errorMessage = error.response?.message || 'Failed to send enquiry. Please try again or contact IT support.';
        setSubmitStatus({
            type: 'error',
            message: errorMessage
        });
    } finally {
        setIsSubmitting(false);
    }
};

  const renderPurcahseContent = () => {
    switch (purchaseOption) {
      case "singleUser":
        return (
          <div>
            <div>
              <hr className="line" />
              <i>Therapeutic Guidelines</i>
              <p>12 month subscription</p>
              <br />
              Includes access to the <i>Therapeutic Guidelines </i>app
            </div>
            <hr className="line" />
            <p>
              Total cost:{" "}
              <span className="cartprice" id="individualPriceId">
                &nbsp;${process.env.REACT_APP_SINGLE_USER_PRICE}
              </span>
            </p>
            <a
              href="#"
              onClick={handleAddToCart}
              className="mTop12px btn btn-info btn-block"
            >
              Add to cart
            </a>
          </div>
        );
      case "multipleUser":
        return (
          <div>
            <div>
              <div>
                <hr className="line" />
                <i>Therapeutic Guidelines</i>
                <p>12 month subscription</p>
                <br />
                <label
                  style={{
                    fontWeight: "normal",
                    fontSize: "12.5px",
                  }}
                >
                  Includes access to the <i>Therapeutic Guidelines</i> app for
                  each licence{" "}
                </label>
              </div>
              <div className="mulit-users-quantity-selector">
                <strong>
                  <b>Select the number of licences </b>
                </strong>
                <select
                  id="quantity"
                  value={quantity}
                  onChange={handleQuantityChange}
                >
                  {[2, 3, 4, 5].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
              </div>
              <hr className="line" />

              <p>
                Total Cost:{" "}
                <span className="cartprice" id="individualPriceId">
                  &nbsp;${multiUserTotalCost}
                </span>
              </p>
              <a
                onClick={handleAddToCart}
                href="#"
                className="mTop12px btn btn-info btn-block"
              >
                Add to cart
              </a>
            </div>
          </div>
        );
      case "institution":
        return (
          <div>
            <hr className="line" />
            <div id="institutionalUserId">
              Please fill in the form below to enquire about institutional
              licence options.
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-group mTop20px">
                <div className="form-group">
                  <input
                    type="text"
                    name="name"
                    className="form-control"
                    placeholder="Name*"
                    value={emailData.name}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group mTop20px">
                <div className="form-group">
                  <input
                    type="email"
                    name="to"
                    className="form-control"
                    placeholder="Email address*"
                    value={emailData.to}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group mTop20px">
                <div className="form-group">
                  <input
                    type="text"
                    name="contactNumber"
                    className="form-control"
                    value={emailData.contactNumber}
                    placeholder="Contact number*"
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group mTop20px">
                <div className="form-group">
                  <input
                    type="text"
                    name="businessName"
                    id=""
                    className="form-control"
                    placeholder="Business name*"
                    value={emailData.businessName}
                    onChange={handleChange}
                  />
                </div>
              </div>
              <div className="form-group mTop20px">
                <div className="form-group">
                  <input
                    type="text"
                    name="message"
                    className="form-control"
                    placeholder="Your message"
                    value={emailData.message}
                    onChange={handleChange}
                  />
                </div>
              </div>
              {error && (
                <div style={{ color: "red", marginBottom: "5px" }}>{error}</div>
              )}
{submitStatus.message && (
    <div className={`alert ${submitStatus.type === 'success' ? 'alert-success' : 'alert-danger'}`}>
        {submitStatus.message}
    </div>
)}
              <button
                type="submit"
                id="enquiryForm_0"
                value="Send enquiry"
                className="mTop12px btn btn-info btn-block noborder"
              >
                {isSubmitting ? 'Sending...' : 'Send enquiry'}
              </button>
            </form>
          </div>
        );
      default:
        return <div></div>;
    }
  };

  const multiUserTotalCost = (unitCost * quantity).toFixed(2);
  return (
    <>
      <div className="purchase-box">
        <h4 className="section-heading nobtmBrdr">Purchase</h4>
        <svg
          width="210"
          height="64"
          style={{ flow: "left", marginLeft: "-24px" }}
        >
          <image
            xlinkHref="/TherapeuticGuidelines_Original_Logo.svg"
            src="./assets/images/tglLogo.png"
            width="210"
            height="64"
            alt="Therapeutic Guidelines"
          ></image>
        </svg>

        <form
          // name="individualUserForm"
          // id="individualUserForm"
          action="addToCart"
          method="post"
        >
          <div className="row btn-group no-margin">
            <div
              className="col-xs-12 col-sm-4 no-padding no-margin"
              data-toggle="buttons"
            >
              <label
                className={`btn btn-primary fullwidth ${
                  purchaseOption === "singleUser" ? "active" : ""
                }`}
              >
                <input
                  type="radio"
                  name="options"
                  checked={purchaseOption === "singleUser"}
                  onChange={() => handleRadioChange("singleUser")}
                />
                Single user
              </label>
            </div>
            <div
              className="col-xs-12 col-sm-4 no-padding no-margin"
              data-toggle="buttons"
            >
              <label
                className={`btn btn-primary fullwidth paddingLeft5px  ${
                  purchaseOption === "multipleUser" ? "active" : ""
                }`}
              >
                <input
                  type="radio"
                  name="options "
                  checked={purchaseOption === "multipleUser"}
                  onChange={() => handleRadioChange("multipleUser")}
                />
                Multiple user
              </label>
            </div>
            <div
              className="col-xs-12 col-sm-4 no-padding no-margin"
              data-toggle="buttons"
            >
              <label
                className={`btn btn-primary fullwidth ${
                  purchaseOption === "institution" ? "active" : ""
                }`}
              >
                <input
                  type="radio"
                  name="options"
                  checked={purchaseOption === "institution"}
                  onChange={() => handleRadioChange("institution")}
                />
                Institution
              </label>
            </div>
          </div>
        </form>
        <div>
          <div className="content">{renderPurcahseContent()}</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  pathname: state.router.location.pathname,
  search: state.router.location.search,
});

export default connect(mapStateToProps, { push })(Purchase);
