import React, { useState, useEffect } from "react";
import axios from "axios";
import VISA from "../../../assets/images/visa-payment.png";
import MasterCard from "../../../assets/images/mastercard-payment.png";
import Amex from "../../../assets/images/amex-payment.png";
import { paymentApi } from "../../../api/paymentApi";
const CardForm = ({ onPayment, amount, onError }) => {
  const [secureFieldCode, setSecureFieldCode] = useState("");
  const [error, setError] = useState(null);
  const [cardType, setCardType] = useState(null);
  const [isEwayLoaded, setIsEwayLoaded] = useState(false);
  const allowedCardTypes = ["visa", "mastercard", "amex"];

  useEffect(() => {
    //eWAY.js script
    paymentApi
      .getEwayPublicAPIKey()
      .then((publicApiKey) => {
        const script = document.createElement("script");
        script.src = "https://secure.ewaypayments.com/scripts/eWAY.min.js";
        script.type = "text/javascript";
        script.dataset.init = "false";
        script.onload = () => {
          setIsEwayLoaded(true);
          initializeSecureFields(publicApiKey);
        };
        document.body.appendChild(script);

        return () => {
          document.body.removeChild(script);
        };
      })
      .catch((error) => {
        console.error("Error fetching eWAY public API key:", error);
      });
  }, []);

  const initializeSecureFields = (publicApiKey) => {
    const commonStyles =
      "height: 40px; padding: 10px; border-radius: 5px;border: 1px solid #ccc;";
    const nameFieldStyles = `${commonStyles} width: 100%; `;
    const cardFieldStyles = `${commonStyles} width: 100%; `;
    const expiryFieldStyles = `${commonStyles} width: 50%;`;
    const cvnFieldStyles = `${commonStyles} width: 50%;`;

    const nameFieldConfig = {
      publicApiKey: publicApiKey,
      fieldDivId: "eway-secure-field-name",
      fieldType: "name",
      styles: nameFieldStyles,
    };

    const cardFieldConfig = {
      publicApiKey: publicApiKey,
      fieldDivId: "eway-secure-field-card",
      fieldType: "card",
      styles: cardFieldStyles,
    };

    const expiryFieldConfig = {
      publicApiKey: publicApiKey,
      fieldDivId: "eway-secure-field-expiry",
      fieldType: "expiry",
      styles: expiryFieldStyles,
    };

    const cvnFieldConfig = {
      publicApiKey: publicApiKey,
      fieldDivId: "eway-secure-field-cvn",
      fieldType: "cvn",
      styles: cvnFieldStyles,
    };

    const secureFieldCallback = (event) => {
      if (!event.fieldValid) {
        setError(event.errors);
      } else {
        setError(null);
      }
      // Set the secureFieldCode when all fields are valid
      if (event.secureFieldCode) {
        setSecureFieldCode(event.secureFieldCode);
      }
    };
    // Initialize the Secure Fields
    window.eWAY.setupSecureField(nameFieldConfig, secureFieldCallback);
    window.eWAY.setupSecureField(cardFieldConfig, secureFieldCallback);
    window.eWAY.setupSecureField(expiryFieldConfig, secureFieldCallback);
    window.eWAY.setupSecureField(cvnFieldConfig, secureFieldCallback);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("submitting payment form", e);
    const doneCallback = () => {
      if (secureFieldCode) {
        processPayment(secureFieldCode);
      } else {
        setError("Please fill in all credit card details correctly.");
      }
    };

    // Save all fields before submitting
    window.eWAY.saveAllFields(doneCallback, 2000);
  };

  const processPayment = async (secureFieldCode) => {
    try {
      const paymentData = {
        Payment: {
          TotalAmount: amount,
        },
        TransactionType: "Purchase",
        SecuredCardData: secureFieldCode,
      };

      const response = await paymentApi.processPayment(paymentData);

      if (response.TransactionStatus) {
        onPayment({
          paymentType: "cc",
          transactionId: response.TransactionID,
          status: "success",
          amount: amount,
          cardType: response.CardType,
        });
      } else {

        onError({
          type: "credit Card",
          message: response.ResponseMessage || "eway Payment failed",
          code: response.responseCode,
        //  responseMessage: response.responseMessage
        });
      }
    } catch (error) {
      console.log("Payment error:", error);
      const responseMessage = error?.response?.status || 500;
       const errorName = error?.response?.data?.name || "CAPTURE_ERROR";
          
              onError({
                type: "credit Card",
                code: errorName,
                message: "Failed to capture PayPal payment. Please try again later.",
              });
            }
    
  };

  return (
    <div className="card-form-cc mTop12px">
      <div className="payment-img-box">
        <img
          src={VISA}
          className="payment-img"
          alt="visa"
        />
        <img
          src={MasterCard}
          className="payment-img"
          alt="MasterCard"
        />
        <img
          src={Amex}
          className="payment-img"
          alt="amex"
        />
      </div>
      <form onSubmit={handleSubmit} className="cc-payment-form">
        <div className="form-group">
          <label htmlFor="eway-secure-field-name" className="form-label">
            Cardholder Name
          </label>
          <div id="eway-secure-field-name" className="cc-form-control"></div>
        </div>
        <div className="form-group">
          <label htmlFor="eway-secure-field-card" className="form-label">
            Card Number
          </label>
          <div id="eway-secure-field-card" className="cc-form-control"></div>
        </div>
        <div className="form-row">
          <div className="form-group" style={{ flex: 1, marginRight: "10px" }}>
            <label htmlFor="eway-secure-field-expiry" className="form-label">
              Expiration Date
            </label>
            <div
              id="eway-secure-field-expiry"
              className="cc-form-control"
            ></div>
          </div>
          <div className="form-group" style={{ flex: 1 }}>
            <label htmlFor="eway-secure-field-cvn" className="form-label">
              CVV
            </label>
            <div id="eway-secure-field-cvn" className="cc-form-control"></div>
          </div>
        </div>
        {error && <div className="alert alert-danger">{error}</div>}
        <button className="btn btn-primary" type="submit">
          Pay
        </button>
      </form>
    </div>
  );
};

export default CardForm;
