import React from "react";

const SelectField = ({ label, id, name, value, options, onChange }) => (
  <div className="form-group mTop12px">
    <label htmlFor={id} className="col-lg-4 col-md-4 col-sm-5 control-label">
      {label}:<span className="red">*</span>
    </label>
    <div className="col-lg-8 col-md-8 col-sm-7">
      <select
        id={id}
        name={name}
        className="form-control"
        value={value}
        onChange={onChange}
      >
        <option value="">-- Select {label.toLowerCase()} --</option>
        {options.map((option, index) => (
          <option
            key={index}
            value={typeof option === "object" ? option.value : option}
          >
            {typeof option === "object" ? option.text : option}
          </option>
        ))}
      </select>
    </div>
  </div>
);

export default SelectField;
