/* eslint-disable */
import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import VISA from "../../../assets/images/visa-payment.png";
import MasterCard from "../../../assets/images/mastercard-payment.png";
import PayPal from "../../../assets/images/paypal-payment.png";
import Amex from "../../../assets/images/amex-payment.png";
import { apiService } from "../../../api/dataverseApi";
import { updateMultiCart } from "../../../store/reducers/subscripion";

const OrderSummaryForm = (props) => {
  const { cartItem, updatePaymentType, onPriceUpdate, onQuantityUpdate, onCouponApplied, onProcessFreeCheckout, nextSteps } =
    props;

  const dispatch = useDispatch();
  const [totalAmount, setTotalAmount] = useState(0);
  const [paymentType, setPaymentType] = useState(null);
  const [promoCode, setPromoCode] = useState("");
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
  const [activeCoupon, setActiveCoupon] = useState(null);
  const [isUpdatingQuantity, setIsUpdatingQuantity] = useState(false);

 useEffect(() => {
            const initialPrice = cartItem.subscriptionid === "151" 
              ? cartItem.individualprice 
              : cartItem.multipleprice;
            setTotalAmount(initialPrice);
            onPriceUpdate(initialPrice); 
            
  }, [cartItem, onPriceUpdate]); 

  const handleMulitQuantityChange = async (e) => {
    const newQuantity = parseInt(e.target.value);
    if (isNaN(newQuantity)) {
      console.error("Invalid quantity value");
      return;
    }

    setIsUpdatingQuantity(true);

    try {
      // First update cart quantity
      const data = {
        cartsid: cartItem.cartsid,
        quantity: newQuantity
      };

      const response = await apiService.updateCartItem(data);

      if (response && response.success) {
        let finalPrice = response.data.multipleprice;
        onQuantityUpdate(newQuantity);

        // If there's an active coupon, reapply it with new quantity
        if (activeCoupon) {
          try {
            const couponResponse = await fetch("/api/CouponApi", {
              method: "PUT",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                action: "validate",
                couponCode: activeCoupon.couponCode,
                singleCartAmount: 0, // Since this is multi-user
                multiCartAmount: response.data.multipleprice,
                subscriptionType: cartItem.subscriptionid,
              }),
            });

            if (couponResponse.ok) {
              const couponData = await couponResponse.json();
              if (couponData.valid) {
                finalPrice = Number(couponData.updatedPrices.multiCartAmount);
                console.log("Coupon applied with new quantity:", couponData);
                onCouponApplied(couponData);
              } else {
                setActiveCoupon(null);
                alert("Coupon no longer valid with new quantity");
              }
            }
          } catch (couponError) {
            console.error("Error reapplying coupon:", couponError);
          }
        }

        onPriceUpdate(finalPrice);
        setTotalAmount(finalPrice);

        dispatch(
          updateMultiCart({ 
            quantity: newQuantity, 
            multiplePrice: finalPrice 
          })
        );
      } else {
        console.error("Failed to update cart:", response);
        alert("Failed to update quantity. Please try again.");
      }
    } catch (err) {
      console.error("Failed to modify cart item:", err);
      alert("An error occurred while updating quantity.");
    } finally {
      setIsUpdatingQuantity(false);
    }
  };


  const handlePaymentType = (event) => {
    const selectedPaymentType = event.target.value;
    setPaymentType(selectedPaymentType);
    if (selectedPaymentType !== null) {
      updatePaymentType(selectedPaymentType);
      props.nextSteps();
      
    }
  };
  const isComplimentary = activeCoupon?.discountType === 3;
  const handlePromoCode = async () => {

    if (!promoCode) {
      alert("Please enter a promo code");
      return;
    }
    if (activeCoupon) {
      alert("You can only apply one coupon per checkout");
      return;
    }

    if (cartItem.hasappliedcoupon) {
      alert("You have previously used a coupon. According to TG policy, each user can only apply a coupon once.");
      return;
    }

    if (isApplyingCoupon) return;

    setIsApplyingCoupon(true);

    try {
      if (!cartItem || !cartItem.subscriptionid) {
        alert("No valid item in cart");
        return;
      }

      const response = await fetch("/api/CouponApi", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          action: "validate",
          couponCode: promoCode.trim(),
          singleCartAmount:
            cartItem.subscriptionid === "151" ? totalAmount : 0,
          multiCartAmount:
            cartItem.subscriptionid === "168" ? totalAmount : 0,
          subscriptionType: cartItem.subscriptionid,
        }),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      console.log("promo code data", data);

      if (data.valid) {
        setActiveCoupon({
          couponId: data.couponId,
          couponCode: data.couponCode,
          discountType: data.discountType,
          applicableTypes: data.applicableTypes
        });
        onCouponApplied(data);
        let newPrice = 0;
        if (cartItem.subscriptionid === "151") {
          newPrice = Number(data.updatedPrices.singleCartAmount);
        } else if (cartItem.subscriptionid === "168") {
          newPrice = Number(data.updatedPrices.multiCartAmount);
        }

        // Validate the new price
        if (isNaN(newPrice) || newPrice < 0) {
          throw new Error("Invalid price received from server");
        }

        onPriceUpdate(newPrice);
        setTotalAmount(newPrice);
        alert("Promo code applied successfully!");
      } else {
        alert(data.message || "Invalid promo code");
        setPromoCode("");
      }
    } catch (error) {
      console.error("Error applying promo code:", error);
      alert("Failed to apply promo code. Please try again later.");
      setPromoCode("");
    } finally {
      setIsApplyingCoupon(false);
    }
  };

  return (
    <div className="panel-body checkout-steps order-summary-form">
      <h4 className="section-heading">Shopping cart</h4>
      <div id="responsive-tables">
        <table className="table table-bordered" id="myPurchases">
          <thead>
            <tr>
            <th className="col-lg-10" style={{ width: "70%" }}>Product</th>
    <th style={{ width: "15%" }}>Quantity</th>
    <th nowrap="nowrap" style={{ width: "15%" }}>Total price (AUD, incl GST)</th>
            </tr>
          </thead>
          <tbody>
            <tr
              className={
                cartItem.subscriptionid === "151"
                  ? "SingleUser-order-summary"
                  : "multiUser-order-summary border-row"
              }
            >
              <td data-title="Product">
              {cartItem.subscriptionid === "151"
    ? `Therapeutic Guidelines SingleUser Subscription${
        cartItem.ordertype === "Renew"
          ? " - renew license(s)"
          : cartItem.ordertype === "Addon"
          ? " - addon license(s)"
          : ""
      }`
    : `Therapeutic Guidelines MultiUser Subscriptions${
        cartItem.ordertype === "Renew"
          ? " - renew license(s)"
          : cartItem.ordertype === "Addon"
          ? " - addon license(s)"
          : ""
      }`}
              </td>
              <td data-title="Quantity" className="quantity-cell">
                {(cartItem.subscriptionid === "168" && cartItem.ordertype==="New") ? (
                  <select
                  name="quantitylist"
                  id="quantitylist"
                  className="form-control reducedPadding"
                  style={{ width: "70px" }}
                  value={cartItem.quantity}
                  onChange={handleMulitQuantityChange}
                  disabled={paymentType !== null}
                >
                  {[2, 3, 4, 5].map((value) => (
                    <option key={value} value={value}>
                      {value}
                    </option>
                  ))}
                </select>
                ) : (
                  <span className="quantity-span">{cartItem.quantity}</span>   
                )}
              </td>
              <td data-title="Price" id="finalPrices">
              ${totalAmount.toFixed(2)}
              </td>
            </tr>
            <tr className="footr_color">
              <td className="totalprice text-right" colSpan="2">
                Subtotal :
              </td>
              <td className="totalprice" id="finalPrice">
              ${totalAmount.toFixed(2)}
              </td>
            </tr>
            <tr>
              <td colSpan="3" className="col-lg-12 form-group">
                <label htmlFor="promoCode" className="sr-only"></label>
                <div className="col-lg-10 col-xs-10 noLeftPaddingXs">
                  <input
                    type="text"
                    className="form-control"
                    id="promoCodeValue"
                    name="promoCodeValue"
                    placeholder="Promo code"
                    value={promoCode}
                    onChange={(e) => setPromoCode(e.target.value)}
                    disabled={isApplyingCoupon|| activeCoupon || paymentType !== null}
                  />
                </div>
                <div className="col-lg-2 col-xs-2">
                  <button
                    className="btn btn-info noborder pull-right marginxs margintp12px"
                    onClick={handlePromoCode}
                    disabled={isApplyingCoupon || paymentType !== null}
                  >
                    {isApplyingCoupon ? "Applying..." : "Apply"}
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr className="footr_color">
              <td className="totalprice text-right col-lg-9" colSpan="2">
                Total :
              </td>
              <td className="totalprice col-lg-3" id="totalPriceId">
                ${totalAmount.toFixed(2)}
              </td>
            </tr>
          </tfoot>
        </table>

        {isComplimentary?(
           <div className="complimentary-message p-4 bg-green-50 rounded-md">
           <h4 className="text-lg font-semibold text-green-700 mb-2">
            Complimentary Access
          </h4>
          <p className="text-green-600 mb-4">
            Your order is complimentary. No payment is required.
          </p>
          <div className="pull-right">
            <button
              className="btn btn-primary"
              onClick={onProcessFreeCheckout}
            >
              Click here to finalise your order
            </button>
            </div>
         </div>):<>
         
        <h4 id="paymentBox" className="section-heading">
          Select payment method:
        </h4>
        <div id="paymentImg">
          <table style={{ marginRight: "0px", marginLeft: "auto" }}>
            <tbody>
              <tr id="cardlogos">
                <td className="col1 labels">&nbsp;</td>
                <td className="col2 fields">
                  <input
                    type="radio"
                    name="_SBILL_IN_paychoice"
                    id="_SBILL_IN_paychoice_cc"
                    value="cc"
                    onChange={handlePaymentType}
                  />
                  <label htmlFor="_SBILL_IN_paychoice_cc">
                    <img src={VISA} className="payment-img" alt="Visa" />
                  </label>
                  <label htmlFor="_SBILL_IN_paychoice_cc">
                    <img
                      src={MasterCard}
                      className="payment-img"
                      alt="MasterCard"
                    />
                  </label>
                  <label htmlFor="_SBILL_IN_paychoice_cc">
                    <img src={Amex} className="payment-img" alt="Amex" />
                  </label>
                  <br />
                  <input
                    type="radio"
                    name="_SBILL_IN_paychoice"
                    id="_SBILL_IN_paychoice_paypal"
                    value="paypal"
                    onChange={handlePaymentType}
                  />
                  <label htmlFor="_SBILL_IN_paychoice_paypal">
                    <img src={PayPal} className="payment-img" alt="PayPal" />
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
         </>
        }

      </div>
    </div>
  );
};


export default OrderSummaryForm;
